import { Refine,    
    AuthBindings,
    Authenticated, } from "@refinedev/core";
import {
    notificationProvider,
    RefineThemes,
    ErrorComponent,
    ThemedLayoutV2,
    ThemedSiderV2,
    ThemedTitleV2
} from "@refinedev/antd";
import dataProvider from "../src/utility";
import routerProvider, {
    NavigateToResource,
    CatchAllNavigate,
} from "@refinedev/react-router-v6";
import { BrowserRouter, Routes, Route, Outlet, useMatch  } from "react-router-dom";

import {Home} from "./pages/home";
import {LiveVersionsList, LiveVersionShow, LiveVersionEdit} from "./pages/live-versions";


import { newModel, MemoryAdapter, newEnforcer } from "casbin.js";
import { model } from "accessControl";

import {
    SettingOutlined
} from "@ant-design/icons";

import Login from "../src/pages/login/login";

import { useAuth0 } from "@auth0/auth0-react";
import axios from "axios";

import { ConfigProvider, Button } from "antd";
import "@refinedev/antd/dist/reset.css";

import Redirect from "./pages/redirect/redirect";
import { Header } from "../src/components/header/index";

import { AwsRum, AwsRumConfig } from 'aws-rum-web';
import { BlueUsersList, BlueUserShow, BlueUserCreate, BlueUserEdit  } from "pages/blue-green";
import { SettingsShow } from "./pages/settings";
import { DemosList, DemoShow, DemoCreate } from "pages/demos";

let API_URL = process.env.REACT_APP_API_URL || ""
console.log(process.env.REACT_APP_BUILD_TIMESTAMP)
console.log(process.env.REACT_APP_ENV)
console.log(process.env.REACT_APP_API_URL)
console.log("QA-Portal")

try {
    const config: AwsRumConfig = {
      sessionSampleRate: 1,
      guestRoleArn: process.env.REACT_APP_AWS_RUM_GUEST_ROLE_ARN,
      identityPoolId: process.env.REACT_APP_AWS_RUM_IDENTITY_POOL_ID,
      endpoint: "https://dataplane.rum.us-west-2.amazonaws.com",
      telemetries: ["performance","errors","http"],
      allowCookies: true,
      enableXRay: false
    };
  
    const APPLICATION_ID: string = process.env.REACT_APP_AWS_RUM_APPLICATION_ID || "";
    const APPLICATION_VERSION: string = '1.0.0';
    const APPLICATION_REGION: string = 'us-west-2';
  
    const awsRum: AwsRum = new AwsRum(
      APPLICATION_ID,
      APPLICATION_VERSION,
      APPLICATION_REGION,
      config
    );
  } catch (error) {
    // Ignore errors thrown during CloudWatch RUM web client initialization
  }

const App: React.FC = () => {
    const { isLoading, user, logout, getIdTokenClaims } = useAuth0();

    if (isLoading) {
        return <span>loading...</span>;
    }
    

    const authProvider: AuthBindings = {
        login: async () => {
            return {
                success: true,
            };
        },
        logout: async () => {
            logout();
            return {
                success: true,
            };
        },
        onError: async (error) => {
            console.error(error);
            return { error };
        },
        check: async () => {
            try {
                const token = await getIdTokenClaims();
                if (token) {
                    console.log("QA-Portal User is authenticated")
                    console.log(token)
                    localStorage.setItem('token', JSON.stringify(token));
                    axios.defaults.headers.common = {
                        Authorization: `Bearer ${token.__raw}`,
                    };

                    // hard reset to home
                    // if (window.location.pathname != "/"){
                    //     window.location.href = process.env.REACT_APP_ENV == "dev" ? "http://qa-portal." + process.env.REACT_APP_API_BASE_URL: "https://qa-portal." + process.env.REACT_APP_API_BASE_URL;
                    // }

                    // Sanity check for env and token
                    if(process.env.REACT_APP_ENV == "dev" && !token["http://localhost:3000/org_id"] ){
                        console.log("Env is dev. Need token for DEV")
                        logout();
                    }

                    return {
                        authenticated: true,
                        redirectTo: "/",
                    };

                } else {
                    console.log("QA-Portal User is not authenticated")
                    return {
                        authenticated: false,
                        error: {
                            message: "Check failed",
                            name: "Token not found",
                        },
                        redirectTo: "/login/index.html",
                        logout: true,
                    };
                }
            } catch (error: any) {
                console.log("QA-Portal User is not authenticated")
                return {
                    authenticated: false,
                    error: new Error(error),
                    redirectTo: "/login/index.html",
                    logout: true,
                };
            }
        },
        getPermissions: async () => null,
        getIdentity: async () => {            
            if (user) {
                console.log(user)
                return {
                    ...user,
                    avatar: user.picture,
                };
            }
            return null;
        },
    };

    return (
        <BrowserRouter>
            <ConfigProvider theme={RefineThemes.Green}>
            <Refine
                    accessControlProvider={{
                        can: async ({ resource, action, params}) => {
                            var permissions =  `` 

                            const role_permissions = user?.[process.env.REACT_APP_BASE_URL + '/role_permissions']
                            for (let role_permission of role_permissions) {
                                for (let scope of role_permission.scopes) {
                                    permissions = permissions + "p, " + role_permission.name + ", " + scope.split(":")[0] + ", (" + scope.split(":")[1] + ")" + "\n"
                                }
                            }

                            console.log(permissions)
                            const enforcer = await newEnforcer(model, new MemoryAdapter(permissions));
                            
                            const roles = user?.[process.env.REACT_APP_BASE_URL + '/role_permissions']
                            console.log(resource)
                            var can = false

                            for (let role of roles) {
                                const result:any = await enforcer.enforce(
                                    role.name,
                                    resource,
                                    action,
                                );

                                if(result){
                                    const reason: any = null
                                    can = result
                                }

                                // can = true // for testing purposes
                            }

                            const reason = can? null : params?.reason
                            return { can, reason: reason , };
                        },
                    }}
                    authProvider={authProvider}
                    dataProvider={dataProvider(API_URL)}
                    routerProvider={routerProvider}
                    resources={[
                        // {
                        //     name: "login",
                        //     list: "/login"
                        // },
                        {
                            name: "release-management",
                            list: "/release-management",
                            show: "/release-management/show/:id",
                            create: "/release-management/create",
                            edit: "/release-management/edit/:id",
                            meta: {
                                canDelete: true,
                                icon: <SettingOutlined />,
                                label: "Release Management",
                            },
                        },
                        {
                            name: "live-versions",
                            meta: { 
                                parent: "release-management", 
                                label: "Live Versions",
                            },
                            list: "/live-versions",
                            show: "/live-versions/show/:id",
                            create: "/live-versions/create",
                            edit: "/live-versions/edit/:id",
                        },
                        {
                            name: "blue-users",
                            meta: { 
                                parent: "release-management", 
                                label: "Blue Green Management",
                            },
                            list: "/blue-users",
                            show: "/blue-users/show/:id",
                            create: "/blue-users/create",
                            edit: "/blue-users/edit/:id",
                        },
                        {
                            name: "canary-management",
                            meta: { 
                                parent: "release-management", 
                                label: "Canary Management",
                            },
                            list: "/canary-management",
                            show: "/canary-management/show/:id",
                            create: "/canary-management/create",
                            edit: "/canary-management/edit/:id",
                        },
                        {
                            name: "demos",
                            meta: { 
                                label: "Demo Management",
                            },
                            list: "/demos",
                            show: "/demos/show/:id",
                            create: "/demos/create",
                            edit: "/demos/edit/:id",
                        },
                        {
                            name: "settings",
                            list: "/settings",
                            show: "/settings/show/:id",
                            meta: {
                                icon: <SettingOutlined />,
                            },
                        },
                    ]}
                    notificationProvider={notificationProvider}
                    options={{
                        syncWithLocation: true,
                        warnWhenUnsavedChanges: true,
                    }}
                >
                <Routes>
                    <Route
                        element={
                            <Authenticated key="login1" fallback={<Outlet />}>
                                <NavigateToResource resource="login" />
                                <ThemedLayoutV2 ></ThemedLayoutV2>
                            </Authenticated>
                        }
                    >
                        <Route
                            path="/login"
                            element={<Login/>}
                        />
                    </Route>
                    <Route
                        element={
                            <Authenticated redirectOnFail="/login" key="login2">
                                <ThemedLayoutV2 Sider={() => <ThemedSiderV2 fixed Title={({ collapsed }) => (
                                    <ThemedTitleV2 collapsed={false} text="QA Portal"/>
                                        )}/>} initialSiderCollapsed={false} Header={() => <Header sticky />}
                                                Title={({ collapsed }) => (
                                            <ThemedTitleV2
                                                // collapsed is a boolean value that indicates whether the <Sidebar> is collapsed or not
                                                collapsed={collapsed}
                                                text="Authe"
                                            />
                                        )}
                                        OffLayoutArea={() => (
                                            <Button
                                                type="primary"
                                                size="small"
                                                onClick={() =>
                                                    alert("Off layout are clicked")
                                                }
                                                style={{
                                                    position: "fixed",
                                                    left: "8px",
                                                    bottom: "8px",
                                                    zIndex: 1000,
                                                }}
                                            >
                                                Send us Feedback 👋
                                            </Button>
                                        )}
                                    >
                                    <Outlet />
                                </ThemedLayoutV2>
                            </Authenticated>
                        }
                    >
                        <Route path="/">
                            <Route index element={<Home />} />
                            <Route path="/" element={<Home/>} />
                        </Route>
                        <Route path="/release-management">
                            <Route index element={<Home />} />
                            <Route path="setup/index.html" element={<Home />} />
                            <Route path="*" element={<Home/>} />
                        </Route>
                        <Route path="/live-versions">
                            <Route index element={<LiveVersionsList />} />
                            <Route path="show/:id" element={<LiveVersionShow />} />
                            <Route path="edit/:id" element={<LiveVersionEdit />} />
                            <Route path="*" element={<LiveVersionsList/>} />
                        </Route>
                        <Route path="/blue-users">
                            <Route index element={<BlueUsersList />} />
                            <Route path="show/:id" element={<BlueUserShow />} />
                            <Route path="edit/:id" element={<BlueUserEdit />} />
                            <Route path="create" element={<BlueUserCreate />} />
                            <Route path="*" element={<BlueUsersList/>} />
                        </Route>  
                        <Route path="/demos">
                            <Route index element={<DemosList />} />
                            <Route path="show/:id" element={<DemoShow />} />
                            {/* <Route path="edit/:id" element={<DemoEdit />} />*/}
                            <Route path="create" element={<DemoCreate />} /> 
                            <Route path="*" element={<DemosList/>} />
                        </Route>  
                        <Route path="/settings">
                            <Route index element={<SettingsShow />} />
                        </Route>                      
                    </Route>
                    <Route path="*" element={<ErrorComponent />} />
                </Routes>
                </Refine>
            </ConfigProvider>
        </BrowserRouter>
    );
};

export default App;
