import React, { useEffect, useState } from 'react';
import { IResourceComponentsProps, useShow } from "@refinedev/core";

import {
    List,
    useTable,
    DateField,
    TagField,
    EditButton,
    ShowButton,
    TextField,
    DeleteButton,
    Show,
    RefreshButton,
    ListButton,
    Edit,
    SaveButton,
} from "@refinedev/antd";
import dayjs from "dayjs";
import { Table, Space, Typography, Checkbox, Input, Button, Divider, Alert, Tooltip, Spin, Tag, Select } from "antd";
import { SearchOutlined } from '@ant-design/icons';
import { isAddress } from 'viem'

import { ILambdaAlias, ILiveVersionDetail, ILiveVersionDetailedResult } from "interfaces";
import { useAuth0 } from '@auth0/auth0-react';
import { CheckboxChangeEvent } from 'antd/es/checkbox';
import { CheckboxValueType } from 'antd/es/checkbox/Group';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
const { Title, Text } = Typography;
import axios from 'axios';


export const LiveVersionEdit: React.FC<IResourceComponentsProps> = () => {
    const CheckboxGroup = Checkbox.Group;
    let colors = {
        "us-west-2": "green",
        "us-east-1": "lightgreen",
        "eu-central-1": "blue",
        "eu-west-1": "lightblue"
    }
    const {
        user,
    } = useAuth0();
    const local_storage_prefix = "QA_PORTAL-" + process.env.REACT_APP_ENV + "-" + user?.sub + "-" + user[process.env.REACT_APP_BASE_URL + "/org_id"] + "-" + user[process.env.REACT_APP_BASE_URL + "/tenant_id"]   
    let storage_use_region_alias = JSON.parse(localStorage.getItem(local_storage_prefix + '_use_region_alias'))
    const token = JSON.parse(localStorage.getItem('token') || '{}');
    const org_id = token[process.env.REACT_APP_BASE_URL + "/org_id"] 
    const continent = token[process.env.REACT_APP_BASE_URL + "/continent"] 
    const region_config = token[process.env.REACT_APP_BASE_URL + "/region_config"]   
    const region = region_config?.region
    const region_alias = region_config?.region_alias
    const backup_region = region_config?.backup_region
    const backup_region_alias = region_config?.backup_region_alias
    const domain = process.env.REACT_APP_API_BASE_URL 

    const regionAliasToRegion = (regionAlias: string) => {
        if(regionAlias == "pdx"){ 
            return "us-west-2"
        }
        else if(regionAlias == "iad"){ 
            return "us-east-1"
        }
        else if(regionAlias == "fra"){ 
            return "eu-central-1"
        }
        else if(regionAlias == "dub"){ 
            return "eu-west-1"
        }                 
    };

    const use_region = storage_use_region_alias ? regionAliasToRegion(storage_use_region_alias) : region
    const use_region_alias = storage_use_region_alias ? storage_use_region_alias : region_alias
    
    const navigate = useNavigate();
    const params= useParams()

    const { tableProps, tableQueryResult } = useTable<ILiveVersionDetail>({
        resource: "versions",
        meta: {
            id: params.id
        }
    });

    const { data, isLoading } = tableQueryResult;
    const record = data?.application_summary;
      
    const refreshTableResults = () => {     
        tableQueryResult.refetch()
    };

    let [tableData, setTableData] = React.useState<any>([])
    let [newVersion, setNewVersion] = React.useState<any>(null)
    let [isLoadingNewVersion, setIsLoadingNewVersion] = React.useState<boolean>(false)

    const handleOnChangeSelectVersion = (e: any) => {     
        setNewVersion(e)
        const new_table_data = tableQueryResult?.data?.data
        setTableData(new_table_data)
    };

    async function handleOnClickSaveButton() {
        setIsLoadingNewVersion(true)

        const requestHeaders = {
            Authorization: `Bearer ${token.__raw}`,
            Accept: "application/json, text/plain, */*",
            "Source-Platform": "dashboard",
            "Source-Region": use_region,
            "Destination-Region": use_region
        };

        const application_name = record?.application_name
        const application_region = record?.region
        const application_environment = record?.environment

        const id = application_environment + "-" + application_region + "-" + application_name

        const apiUrl = "https://" + use_region_alias + "." + continent.toLowerCase() + ".api." + domain 
        console.log("Sending the request")
        const url = `${apiUrl}/internal/tools/release-management/ANY/${continent.toUpperCase()}/v0/versions/${id}`

        const obj = {
            "lambda_arns": tableData?.map((a: { lambda_arn: any; }) => a.lambda_arn),
            "new_version": newVersion,
            "api_gateway_id": record?.api_gateway_id
        }
        console.log(obj)

        try {
            const { data, status } = await axios.post(
                url,
                JSON.stringify(obj), {
                    headers: requestHeaders
                }
            )
            console.log(data)
            console.log(status)
            navigate('/live-versions/show/' + id);

        } catch (error: any) {
            console.log(error.response); 
            setIsLoadingNewVersion(false)
            return error.response;
            
          }       


        setIsLoadingNewVersion(false)
    }

    return (

        <List headerButtons={() => (
           <></>
          )}>
            <Edit breadcrumb={false} title={false} isLoading={false} headerButtons={({ listButtonProps, refreshButtonProps }) => (
        <>
          {listButtonProps && <ListButton {...listButtonProps} meta={{ foo: "bar" }} />}
          <RefreshButton {...refreshButtonProps} disabled={tableQueryResult?.isLoading || tableQueryResult?.isFetching } loading={tableQueryResult?.isLoading || tableQueryResult?.isFetching } onClick={refreshTableResults} />
        </>
      )} 
    //   wrapperProps={{
    //     style: {
    //       backgroundColor: "orange",
    //       padding: "16px",
    //     },
    //   }}
    footerButtonProps={{
        style: {
            float: "left",
            marginLeft: "24px"
        },
    }}
      footerButtons={({ saveButtonProps }) => (
        
        <>
          <SaveButton {...saveButtonProps} onClick={handleOnClickSaveButton} loading={isLoadingNewVersion} >Promote/Rollback</SaveButton>
        </>
      )}
      >
                {
                    tableQueryResult?.data?.summary ?
                        <Space direction="vertical" style={{ width: '100%' }}>
                        <Alert
                        message={"Time taken to load the results: " + tableQueryResult?.data?.summary?.summary.time_needed_in_seconds + " seconds"}
                        type="success"

                        />
                    </Space> :
                    ""
                } 
                <Divider></Divider>
                {isLoading ? <Spin size="large" /> : null}
                <Title level={5}>Application name</Title>
                <Text>{record?.application_name}</Text>
                <Title level={5}>Environment</Title>
                <Text>{record?.environment}</Text>
                <Title level={5}>Region</Title>
                <Tag color={colors[record?.region as keyof typeof colors ]}>{record?.region}</Tag>
                <Title level={5}>Region Alias</Title>
                <Text>{record?.region_alias}</Text>
                <Title level={5}>Blue version as displayed in SSM</Title>
                <Text>{record?.blue_version.blue_value}</Text>
                <Title level={5}>Green version as displayed in DB</Title>
                <Text>{record?.green_version.green_value ? record?.green_version.green_value : "1 - Not yet deployed"}</Text>
                <Title level={5}>Api gateway Id</Title>
                {
                    record?.api_gateway_id.includes("Did not find the API Gateway with name") ? <TagField color="red" value={record?.api_gateway_id}></TagField>  :
                    <Text>{record?.api_gateway_id}</Text>
                }
                <Title level={5}>Api gateway Name</Title>
                {
                    record?.api_gateway_name.includes("Did not find the API Gateway with name") ? <TagField color="red" value={record?.api_gateway_name}></TagField>  :
                    <Text>{record?.api_gateway_name}</Text>
                }
                <Title level={5}>Api gateway <span style={{color: "blue"}}>Blue</span> endpoint is pointing to environment</Title>
                <Tag color={record?.api_gateway_stage_variables.LatestEnvironment}>{record?.api_gateway_stage_variables.LatestEnvironment}</Tag>         
                <Title level={5}>Api gateway <span style={{color: "lightgreen"}}>Green</span>  endpoint is pointing to environment</Title>
                <Tag color={record?.api_gateway_stage_variables.LiveEnvironment}>{record?.api_gateway_stage_variables.LiveEnvironment}</Tag>                          
                <Divider></Divider>
                <Title level={5}>Select the version to promote/rollback to:</Title>
                    <Tooltip title="Which version do you want to promote/rollback to">
                    <Select
                        style={{ width: 500 }}
                        defaultValue={record?.green_version.green_value}
                        size='large'
                        onChange={(e) => handleOnChangeSelectVersion(e)}
                        options={record?.all_available_version.map((version_info: any) => ({ "value": version_info, "label": version_info} ))}
                    />
                    </Tooltip>

            </Edit>
            <Divider />     
            <Table {...tableProps} dataSource={tableData} loading={tableQueryResult?.isLoading || tableQueryResult?.isFetching } rowKey="lambda_arn"
            pagination={{
                defaultPageSize:100,
                showSizeChanger:true,
            }}
            
            >
                <Table.Column<ILiveVersionDetailedResult>
                    title="Lambda Arn" 
                    dataIndex="lambda_arn" 
                    render={(_: string) => {
                        return (
                            <Tooltip title={_}>
                                <TagField
                                    value={`${
                                        _.split(":function:").pop()
                                    }` }
                                />
                            </Tooltip>
                        );
                    }}
                />
                <Table.Column<ILiveVersionDetailedResult>
                    title="Lambda has the version available" 
                    dataIndex="lambda_arn" 
                    render={(_: any, record) => {
                        const message = "Selected version might not be available for this Lambda. Lambda will not be updated"
                        const filtered_records = record.lambda_version_result.filter(( version_info: any) => version_info.version == newVersion)
                        if (filtered_records.length > 0 ){
                            return (
                                filtered_records.map((version_info: any) =>  <Tooltip title={version_info.lambda_version}> <TagField id={version_info.version} key={version_info.version} value={version_info.version} color={version_info.lambda_version == "$LATEST" ? "lightblue": version_info.lambda_version == record.lambda_alias_result.aliases_info.filter(( alias: any) => alias.alias_name == "blue")[0]?.lambda_version ? "blue" :  version_info.lambda_version == record.lambda_alias_result.aliases_info.filter((alias: any) => alias.alias_name == "green")[0]?.lambda_version ? "green" : ""} ></TagField></Tooltip>)
                            );
                        } else{
                            return (
                                <TagField value={message} color="red">{message}</TagField>
                            )
                        }

                    }}
                />
                <Table.Column<ILiveVersionDetailedResult>
                    title="Lambda is in API Gateway" 
                    dataIndex="in_api_gateway" 
                    render={(_: any, record) => {
                            return <TagField value={_} color={_ == true ? "purple" : "orange"}>{_}</TagField>;
                        }}
                />
            </Table>
        </List>
    );
};


