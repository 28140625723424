import React, { useEffect, useState } from 'react';
import { IResourceComponentsProps, useNotification } from "@refinedev/core";

import {
    List,
    useTable,
    DateField,
    TagField,
    EditButton,
    ShowButton,
    TextField,
    DeleteButton,
    CreateButton,
} from "@refinedev/antd";
import dayjs from "dayjs";
import { Table, Space, Typography, Tooltip, Divider, Button, Input, Alert } from "antd";
import { SearchOutlined } from '@ant-design/icons';

import { IDemo } from "interfaces";
import { useAuth0 } from '@auth0/auth0-react';
import Checkbox, { CheckboxChangeEvent } from 'antd/es/checkbox';
import { CheckboxValueType } from 'antd/es/checkbox/Group';
const { Title, Text } = Typography;
import axios from 'axios';

export const DemosList: React.FC<IResourceComponentsProps> = () => {
    const CheckboxGroup = Checkbox.Group;

    const {
        user,
    } = useAuth0();
    const local_storage_prefix = "QA_PORTAL-" + process.env.REACT_APP_ENV + "-" + user?.sub + "-" + user[process.env.REACT_APP_BASE_URL + "/org_id"] + "-" + user[process.env.REACT_APP_BASE_URL + "/tenant_id"]   
    let storage_use_region_alias = JSON.parse(localStorage.getItem(local_storage_prefix + '_use_region_alias'))
    const token = JSON.parse(localStorage.getItem('token') || '{}');
    const org_id = token[process.env.REACT_APP_BASE_URL + "/org_id"] 
    const continent = token[process.env.REACT_APP_BASE_URL + "/continent"] 
    const region_config = token[process.env.REACT_APP_BASE_URL + "/region_config"]   
    const region = region_config?.region
    const region_alias = region_config?.region_alias
    const backup_region = region_config?.backup_region
    const backup_region_alias = region_config?.backup_region_alias
    const domain = process.env.REACT_APP_API_BASE_URL 

    const regionAliasToRegion = (regionAlias: string) => {
        if(regionAlias == "pdx"){ 
            return "us-west-2"
        }
        else if(regionAlias == "iad"){ 
            return "us-east-1"
        }
        else if(regionAlias == "fra"){ 
            return "eu-central-1"
        }
        else if(regionAlias == "dub"){ 
            return "eu-west-1"
        }                 
    };

    const use_region = storage_use_region_alias ? regionAliasToRegion(storage_use_region_alias) : region
    const use_region_alias = storage_use_region_alias ? storage_use_region_alias : region_alias
    
    const [searchValue, setSearchValue] = useState<string|undefined>()
    const [finalSearchValue, setFinalSearchValue] = useState<string|undefined>()
    const { open } = useNotification();

    const all_possible_regions = [
        {
            "label": "us-west-2",
            "value": "us-west-2"
        },
        {
            "label": "us-east-1",
            "value": "us-east-1"
        },
        {
            "label": "eu-central-1",
            "value": "eu-central-1"
        },
        {
            "label": "eu-west-1",
            "value": "eu-west-1"
        },
    ]

    const plainOptions = all_possible_regions
    const defaultCheckedList = [use_region] // all_possible_regions.map(a => a.value);

    const [checkedList, setCheckedList] = useState<CheckboxValueType[]>(defaultCheckedList);
    const [searchCheckedList, setSearchCheckedList] = useState<CheckboxValueType[]>(defaultCheckedList);

    const checkAll = plainOptions.length === checkedList.length;
    const indeterminate = checkedList.length > 0 && checkedList.length < plainOptions.length;
    
    const onChange = (list: CheckboxValueType[]) => {
        setCheckedList(list);
    };
    
    const onCheckAllChange = (e: CheckboxChangeEvent) => {
        setCheckedList(e.target.checked ? plainOptions.map(a => a.value) : []);
    };
    


    const { tableProps, tableQueryResult } = useTable<IDemo>({
        resource: "demos",
        meta: { 
            "id": finalSearchValue,
            "regions": searchCheckedList,
        },
    });

    const refreshTableResults = () => {     
        setFinalSearchValue(searchValue)
        setSearchCheckedList(checkedList)   
        tableQueryResult.refetch()
    };

    const [isLoadingTFApply, setIsLoadingTFApply] = useState<boolean>(false);
    const [followBuildHere, setFollowBuildHere] = useState<string>();

    async function handleOnClickTFApplyButton(raw_id: any) {
        console.log(raw_id)
        setIsLoadingTFApply(true)
        const qa_config = token[process.env.REACT_APP_BASE_URL + "/qa_config"] 
        const qa_environment = qa_config["environment"]
        const blue_config = qa_config["config"]

        const requestHeaders = {
            Authorization: `Bearer ${token.__raw}`,
            Accept: "application/json, text/plain, */*",
            "Source-Platform": "qa-portal",
            "Source-Region": region,
            "Destination-Region": region,
        };

        const id = raw_id 

        const apiUrl = "https://" + use_region_alias + "." + continent.toLowerCase() + ".api." + domain 
        console.log("Sending the request")
        let url
        if (qa_environment == "blue"){
            url = `${apiUrl}/internal/tools/demos/blue/GET/${continent.toUpperCase()}/v0/demos/tf-apply/${id}`
        } 
        else{
            url = `${apiUrl}/internal/tools/demos/GET/${continent.toUpperCase()}/v0/demos/tf-apply/${id}`
        }
        console.log(url)
       
        try {
            const { data, status } = await axios.get(
                url,
                {
                    headers: requestHeaders
                }
            )
            console.log(data)
            console.log(status)
            let new_message = "TF Apply requested"
            let new_description = ""
            console.log(data)
            
            if (data.ok){
                setFollowBuildHere(data.result.follow_build_here)
                open?.({
                    type: "success",
                    message: new_message,
                    description: new_description,
                    key: "tf-apply",
                });
            } else{
                open?.({
                    type: "error",
                    message: data.error?.message,
                    description: data.error?.reason,
                    key: "tf-apply",
                });  
            }
        } catch (error: any) {
            console.log(error.response); 
            setIsLoadingTFApply(false)
            
            return error.response;
            
          }       


          setIsLoadingTFApply(false)
    }

    return (

        <List title="Demo management" headerButtons={({ createButtonProps }) => (
            <>
              {createButtonProps && <CreateButton {...createButtonProps} meta={{ foo: "bar" }} />}
            </>
          )}>
            <Title level={5}>Search regions for demos:</Title>
            <Divider />
            <Space.Compact size="large">
            <Checkbox indeterminate={indeterminate} onChange={onCheckAllChange} checked={checkAll}>
                Check all
            </Checkbox>
                <Text>
                {
                    <CheckboxGroup options={plainOptions} value={checkedList} onChange={onChange} />
                }    
                </Text> 
            </Space.Compact>
            <Divider />
            <Title level={5}>Search demo id (can be starts with):</Title>
            <Input addonBefore={<SearchOutlined />} placeholder="Enter the search for demo id" value={searchValue} onChange={(e) => setSearchValue(e.target.value)}/>
            <Divider />
            <Space.Compact style={{ width: '100%' }}>
            <Button type="primary" onClick={refreshTableResults} disabled={tableQueryResult?.isFetching }>Submit</Button>
            </Space.Compact>
            <Divider />
            {
                followBuildHere ?
                    <Space direction="vertical" style={{ width: '100%' }}>
                    <Alert
                    message={<span>New TF demo apply request can be followed here: <a href={followBuildHere} target="_blank"> link </a> </span> }
                    type="success" 
                    />
                    <pre>
                        
                    </pre>
                </Space> :
                ""
            }  
            <Divider />
            <Table {...tableProps} rowKey="lock_value" dataSource={tableQueryResult?.data?.data }
                        pagination={{
                            defaultPageSize:500,
                            showSizeChanger:true,
                        }}
            >
                <Table.Column<IDemo>
                    sorter={ (a: any, b: any) => a.demo_id - b.demo_id}
                    title="Id" 
                    dataIndex="demo_id" 
                    render={(_: any, record: { demo_id: any; }) => {
                        return (
                            <Tooltip title={record?.demo_id}>
                            <TagField
                                value={`${
                                    record?.demo_id
                                }` }
                            />
                            </Tooltip>
                        );
                    }}
                />
                <Table.Column<IDemo>
                    sorter={ (a: any, b: any) => a.org_id - b.org_id}
                    filters={tableQueryResult?.data?.data.map((
                        { org_id }) => ({ "text": org_id, "value": org_id})).filter((v,i,a)=>a.findIndex(v2=>(v2.text===v.text))===i)}
                    filterMode='tree'
                    filterSearch={true}
                    onFilter={(value: any, record: { org_id: string; }) => record.org_id.startsWith(value)}
                    title="Org Id" 
                    dataIndex="org_id" 
                    render={(_: any, record: { org_id: any; }) => {
                        return (
                            <TagField
                                value={`${
                                    record?.org_id
                                }` }
                            />
                        );
                    }}
                /> 
                <Table.Column<IDemo>
                    sorter={ (a: any, b: any) => a.tenant_id - b.tenant_id}
                    filters={tableQueryResult?.data?.data.map((
                        { tenant_id }) => ({ "text": tenant_id, "value": tenant_id})).filter((v,i,a)=>a.findIndex(v2=>(v2.text===v.text))===i)}
                    filterMode='tree'
                    filterSearch={true}
                    onFilter={(value: any, record: { tenant_id: string; }) => record.tenant_id.startsWith(value)}
                    title="Tenant Id" 
                    dataIndex="tenant_id" 
                    render={(_: any, record: { tenant_id: any; }) => {
                        return (
                            <TagField
                                value={`${
                                    record?.tenant_id
                                }` }
                            />
                        );
                    }}
                /> 
                <Table.Column<IDemo>
                    sorter={ (a: any, b: any) => a.name - b.name}
                    filters={tableQueryResult?.data?.data.map((
                        { name }) => ({ "text": name, "value": name})).filter((v,i,a)=>a.findIndex(v2=>(v2.text===v.text))===i)}
                    filterMode='tree'
                    filterSearch={true}
                    onFilter={(value: any, record: { name: string; }) => record.name.startsWith(value)}
                    title="Name" 
                    dataIndex="name" 
                    render={(_: any, record: { name: any; }) => {
                        return (
                            <TagField
                                value={`${
                                    record?.name
                                }` }
                            />
                        );
                    }}
                /> 
                <Table.Column<IDemo>
                    sorter={ (a: any, b: any) => a.description - b.description}
                    filters={tableQueryResult?.data?.data.map((
                        { description }) => ({ "text": description, "value": description})).filter((v,i,a)=>a.findIndex(v2=>(v2.text===v.text))===i)}
                    filterMode='tree'
                    filterSearch={true}
                    onFilter={(value: any, record: { description: string; }) => record.description.startsWith(value)}
                    title="Description" 
                    dataIndex="description" 
                    render={(_: any, record: { description: any; }) => {
                        return (
                            <Text>{ record?.description}</Text>
                        );
                    }}
                /> 
                <Table.Column<IDemo>
                    sorter={ (a: any, b: any) => a.status - b.status}
                    filters={tableQueryResult?.data?.data.map((
                        { status }) => ({ "text": status, "value": status})).filter((v,i,a)=>a.findIndex(v2=>(v2.text===v.text))===i)}
                    filterMode='tree'
                    filterSearch={true}
                    onFilter={(value: any, record: { status: string; }) => record.status.startsWith(value)}
                    title="Status" 
                    dataIndex="status" 
                    render={(_: any, record: { status: any; }) => {
                        return (
                            <Text>{ record?.status}</Text>
                        );
                    }}
                /> 
                <Table.Column<IDemo>
                    title="Actions"
                    dataIndex="demo_id"
                    render={(_: any, record: {
                            demo_id: string;
                            environment: string; 
                            region: string; 
                        }) => (
                        <Space>
                            <EditButton
                                size="small"
                                recordItemId={record.environment + "_" + record.region + "_" + record.demo_id }
                                onClick={(e) => {
                                    const new_id = record.environment + "_" + record.region + "_" + record.demo_id
                                    handleOnClickTFApplyButton(new_id)
                                }}
                                loading={isLoadingTFApply} 
                            >TF Re-apply</EditButton>
                            <ShowButton
                                // hideText
                                size="small"
                                recordItemId={record.environment + "_" + record.region + "_" + record.demo_id }
                            />
                        </Space>
                    )}
                /> 
            </Table>
        </List>
    );
};

