import React from "react";
import { IResourceComponentsProps } from "@refinedev/core";

import { Create, Edit, useForm } from "@refinedev/antd";

import { Alert, Checkbox, Divider, Form, Input, Select, Space, Tooltip } from "antd";

import { IDemo } from "interfaces";
export const DemoCreate: React.FC<IResourceComponentsProps> = () => {
        
    const { formLoading ,formProps, saveButtonProps, onFinish, mutationResult } = useForm<IDemo>(
        {
            resource: "demos",
            action: "create",
            redirect: false,
        }
    );

    const { Option } = Select;

    return (
        <Create isLoading={formLoading} saveButtonProps={saveButtonProps} resource="demos" title="Create Demo">
            {
                mutationResult?.data?.data.follow_build_here ?
                    <Space direction="vertical" style={{ width: '100%' }}>
                    <Alert
                    message={<span>New demo request can be followed here: <a href={mutationResult?.data?.data?.follow_build_here} target="_blank"> link </a> </span> }
                    type="success" 
                    />
                    <pre>
                        
                    </pre>
                </Space> :
                ""
            }  

            <Form {...formProps} layout="vertical" >
                <Form.Item
                    label="Environment"
                    name="environment"  
                    initialValue={process.env.REACT_APP_ENV == "dev" ? "sandbox" : process.env.REACT_APP_ENV}                 
                    rules={[
                        {
                            required: true,
                        },
                    ]}
                >
                    <Input disabled={true}/>
                </Form.Item>
                <Form.Item
                    label="Region"
                    name="region"  
                    rules={[
                        {
                            required: true,
                        },
                        {
                            validator: async (_, value) => {
                                if (!value) return;
                                    console.log(value)
                                    // if(value.includes("global") && value.length > 1){
                                    //     return Promise.reject(
                                    //         new Error("Can not have global and regions selected at the same time. Only select global or regions")
                                    //     );
                                    // }
      
                                    return Promise.resolve();

                            },
                        },
                    ]}
                >
                      <Select 
                        // mode="multiple"
                        placeholder="Please select a region">
                        <Option value="us-west-2">us-west-2</Option>
                        <Option value="us-east-1">us-east-1</Option>
                        <Option value="eu-central-1">eu-central-1</Option>
                        <Option value="eu-west-1">eu-west-1</Option>
                    </Select>
                </Form.Item>
                <Form.Item
                    label="Type"
                    name="type"  
                    rules={[
                        {
                            required: true,
                        },
                    ]}
                >
                      <Select 
                        placeholder="Please select a type of company to provision">
                        <Option value="small">Small</Option>
                        <Option value="medium">Medium</Option>
                        <Option value="large">Large</Option>
                        <Option value="none">None</Option>
                    </Select>
                </Form.Item>
                <Form.Item
                    label="Name"
                    name="name"                    
                    rules={[
                        {
                            required: true,
                        },
                        {
                            validator: async (_, value) => {
                                if (!value) return;
                                    console.log(value)
                                    if(value.startsWith('/')){
                                        return Promise.reject(
                                            new Error("Can not start with a slash '/'")
                                        );
                                    }
                                    if(value.endsWith('/')){
                                        return Promise.reject(
                                            new Error("Can not end with '/'")
                                        );
                                    }   
                                    if(value.includes('.')){
                                        return Promise.reject(
                                            new Error("Can not contain '.'")
                                        );
                                    }    
                                    if(value.includes(' ')){
                                        return Promise.reject(
                                            new Error("Can not contain ' '")
                                        );
                                    }   
                                    return Promise.resolve();
                            },
                        },
                    ]}
                >
                    <Input placeholder="Demo name"/>
                </Form.Item>
                <Form.Item
                    label="Description"
                    name="description"                    
                    rules={[
                        {
                            required: true,
                        },
                    ]}
                >
                    <Input placeholder="Description of the demo"/>
                </Form.Item>
                <Form.Item
                    label="Number of pre-provisioned users"
                    name="pre_provisioned_users"                    
                    rules={[
                        {
                            required: true,
                        },
                    ]}
                >
                    <Input placeholder="1"/>
                </Form.Item>
            </Form>
            <Space>
                <Alert
                    message="Create Demo info: "
                    description="A codebuild project will be run to provision the test users and test configuration"
                    type="info"
                    />
            </Space>   
        </Create>
    );
};
