// The login page for the sandbox / prd environments is managed by the authentication-loginpage repo
// This is just kept here for local testing the whole application and to have a login place
import { useNavigate } from 'react-router-dom';

import axios from 'axios';

import { Layout as AntdLayout, Button, Row, Col } from "antd";

import { useAuth0 } from "@auth0/auth0-react";
import { useEffect } from 'react';

import { useSearchParams } from "react-router-dom";

export default function Login() {
    console.log("QA-Portal - login component")
    const base_url = process.env.REACT_APP_ENV == "dev" ? process.env.REACT_APP_BASE_URL : "https://qa-portal." + process.env.REACT_APP_API_BASE_URL  
    console.log(base_url)

    const [searchParams, setSearchParams] = useSearchParams();

    const { loginWithRedirect } = useAuth0();

    useEffect(() => {
        const invite = searchParams.get("invite")
        const org_id = searchParams.get("org_id")
        const tenant_id = searchParams.get("tenant_id")
        const roles = searchParams.get("roles")
        const email = searchParams.get("roles")
        
        console.log(invite)
        console.log(org_id)
        console.log(tenant_id)
        console.log(roles)
        if(invite){
            loginWithRedirect(
                {
                    authorizationParams:
                        {
                            invite:invite,
                            org_id: org_id,
                            tenant_id: tenant_id,
                            roles: roles
                        },
                        appState: {
                            returnTo: '/', // First login should go to setup page
                            login_hint: "login_hint",
                        },
                        
                }
            )
        }
        else{
            loginWithRedirect(
                {
                    authorizationParams:
                        {
                            redirect_uri: base_url
                        },
                        appState: {
                            returnTo: '/' // Test if it can be overwritten with no issues for the refresh token?
                        }
                }
            )
        }
    });

    return (
        <AntdLayout
            style={{
                background: `#aab3da`,
                backgroundSize: "cover",
            }}
        >
            <Row
                justify="center"
                align="middle"
                style={{
                    height: "100vh",
                }}
            >
                <Col xs={22}>
                    <div
                        style={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                            marginBottom: "28px",
                        }}
                    >
                        {/* <img src="./refine.svg" alt="Refine" /> */}
                    </div>
                    <div style={{ display: "flex", justifyContent: "center" }}>
                  
                    </div>
                </Col>
            </Row>
        </AntdLayout>
    );
}
