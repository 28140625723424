import React, { useEffect, useState } from 'react';
import { IResourceComponentsProps } from "@refinedev/core";

import {
    List,
    useTable,
    DateField,
    TagField,
    RefreshButton,
    CreateButton,
    EditButton,
    ShowButton,
    DeleteButton,
    TextField,
} from "@refinedev/antd";
import dayjs from "dayjs";
import { Table, Space, Tooltip, Alert, Divider } from "antd";

import { IBlueUser } from "interfaces";


export const BlueUsersList: React.FC<IResourceComponentsProps> = () => {
    const { tableProps, sorter, tableQueryResult } = useTable<IBlueUser>({
        resource: "blue-users"
    });

    const refreshTableResults = () => {     
        tableQueryResult.refetch()
    };

    return (
        <List headerButtons={({ createButtonProps }) => (
            <>
              {createButtonProps && <CreateButton {...createButtonProps} meta={{ foo: "bar" }} />}
              <RefreshButton disabled={tableQueryResult?.isLoading || tableQueryResult?.isFetching } loading={tableQueryResult?.isLoading || tableQueryResult?.isFetching } onClick={refreshTableResults} />
            </>
          )}>
            {
                tableQueryResult?.data?.summary?.summary.time_needed_in_seconds ?
                    <Space direction="vertical" style={{ width: '100%' }}>
                    <Alert
                    message={"Time taken to load the results: " + tableQueryResult?.data?.summary.summary.time_needed_in_seconds + " seconds"}
                    type="success"

                    />
                </Space> :
                ""
            }      
            <Divider />  
            <Table {...tableProps} rowKey={"id"}
                pagination={{
                    defaultPageSize:500,
                    showSizeChanger:true,
                }}>
                <Table.Column<IBlueUser>
                    title="User_id" 
                    dataIndex="user_id" 
                    render={(_: any, record: { user_id: any; }) => {
                        return (
                            <Tooltip title={record?.user_id}>
                                <TagField
                                    value={`${
                                        record?.user_id
                                    }` }
                                />
                            </Tooltip>
                        );
                    }}
                />
                <Table.Column<IBlueUser>
                    title="Label" 
                    dataIndex="label" 
                    render={(_: any, record: { label: any; }) => {
                        return (
                            <TextField value={record.label} ></TextField>
                        );
                    }}
                />
                <Table.Column<IBlueUser>
                    title="Tenant" 
                    dataIndex="tenant_id-application_name" 
                    render={(_: any, record: { 'tenant_id-application_name': any; }) => {
                        return (
                            <TagField
                                value={`${
                                    record?.['tenant_id-application_name'].split("-")[0]
                                }` }
                            />
                        );
                    }}
                />
                <Table.Column<IBlueUser>
                    title="Application" 
                    dataIndex="application" 
                    render={(_: any, record: { application: any; }) => {
                        return (
                            <TagField
                                value={`${
                                    record.application
                                }` }
                            />
                        );
                    }}
                />
                <Table.Column<IBlueUser>
                    title="Created at" 
                    dataIndex="created_at" 
                    render={(_: any, record: { created_at: any; }) => {
                        return (
                            <DateField value={dayjs.unix(Number(record?.created_at) || 1)} format="LLL"></DateField>
                        );
                    }}
                />
                <Table.Column<IBlueUser>
                    title="Description" 
                    dataIndex="description" 
                    render={(_: any, record: { description: any; }) => {
                        return (
                            <TextField value={record.description} ></TextField>
                        );
                    }}
                />
                <Table.Column<IBlueUser>
                    title="Actions"
                    dataIndex="user_id"
                    render={(_: any, record: { user_id: any; 'tenant_id-application_name': string}) => (
                        <Space>
                            <EditButton
                                hideText
                                size="small"
                                recordItemId={record.user_id + "-" + record['tenant_id-application_name']}
                            />
                            <ShowButton
                                hideText
                                size="small"
                                recordItemId={record.user_id + "-" + record['tenant_id-application_name']}
                                meta={{ authorId: "10" }}
                            />
                            <DeleteButton
                                size="small"
                                hideText
                                recordItemId={record.user_id + "-" + record['tenant_id-application_name']}
                            />
                        </Space>
                    )}
                />  
   
            </Table>
        </List>
    );
};

