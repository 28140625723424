import React, { useEffect, useState } from 'react';
import { IResourceComponentsProps, useShow } from "@refinedev/core";

import {
    List,
    useTable,
    Edit,
    useForm
} from "@refinedev/antd";
import dayjs from "dayjs";
import { Space, Typography, Checkbox, Divider, Alert, Tooltip, Spin, Tag, Select, Form, Button, Input } from "antd";


import { IBlueUser } from "interfaces";
import { MinusCircleOutlined, PlusOutlined } from "@ant-design/icons";

const { Title, Text } = Typography;


export const BlueUserEdit: React.FC<IResourceComponentsProps> = () => {


    const { formProps, saveButtonProps, queryResult } = useForm<IBlueUser>();

    const postData = queryResult?.data?.data;

    const { Option } = Select;

    const formItemLayout = {
        labelCol: {
          xs: { span: 24 },
          sm: { span: 4 },
        },
        wrapperCol: {
          xs: { span: 24 },
          sm: { span: 20 },
        },
      };
      
      const formItemLayoutWithOutLabel = {
        wrapperCol: {
          xs: { span: 24, offset: 0 },
          sm: { span: 20, offset: 4 },
        },
      };

    return (
        <Edit saveButtonProps={{ ...saveButtonProps }}>
            <Form {...formProps} layout="vertical">
                <Form.Item
                    label="User Id"
                    name="user_id"                    
                    rules={[
                        {
                            required: true,
                        },
                    ]}
                >
                    <Input disabled={true}/>
                </Form.Item>
                <Form.Item
                    label="Label"
                    name="label"                    
                    rules={[
                        {
                            required: true,
                        },
                    ]}
                >
                    <Input disabled={true}/>
                </Form.Item>
                <Form.Item
                    label="Tenant Id"
                    name="tenant_id"                    
                    rules={[
                        {
                            required: true,
                        },
                    ]}
                >
                    <Input disabled={true}/>
                </Form.Item>
                <Form.Item
                    label="Description"
                    name="description"                    
                    rules={[
                        {
                            required: true,
                        },
                    ]}
                >
                    <Input/>
                </Form.Item>
                <Form.Item
                    label="Type"
                    name="type"                    
                    rules={[
                        {
                            required: true,
                        },
                    ]}
                >
                    <Input disabled={true}/>
                </Form.Item>
                <Form.Item
                    label="Application"
                    name="application"                    
                    rules={[
                        {
                            required: true,
                        },
                    ]}
                >
                    <Input disabled={true}/>
                </Form.Item>
                <Form.List
                    name="config"
                    // rules={[
                    // {
                    //     validator: async (_, config) => {
                    //     if (!config || config.length < 0) {
                    //         return Promise.reject(new Error("At least 2 passengers"));
                    //     }
                    //     },
                    // },
                    // ]}
                >
                    {(fields, { add, remove }, { errors }) => (
                    <>
                        {fields.map((field, index) => (
                        <Form.Item
                            {...formItemLayout}
                            label={index === 0 ? "Service Config:" : ""}
                            required={false}
                            key={field.key}
                            initialValue={postData?.config}    
                        >
                            <Form.Item
                            {...field}
                            validateTrigger={["onChange", "onBlur"]}
                            rules={[
                                {
                                required: false,
                                whitespace: true,
                                message:
                                    "Please input the service resource name. For example: permissions.",
                                },
                            ]}
                            noStyle
                            >
                            <Input
                                placeholder="service resource name"
                                style={{ width: "60%" }}
                            />
                            </Form.Item>
                            {fields.length > 0 ? (
                            <MinusCircleOutlined
                                className="dynamic-delete-button"
                                onClick={() => remove(field.name)}
                            />
                            ) : null}
                        </Form.Item>
                        ))}
                        <Form.Item>
                        <Button
                            type="dashed"
                            onClick={() => add()}
                            style={{ width: "60%" }}
                            icon={<PlusOutlined />}
                        >
                            Add Service config
                        </Button>
                        <Form.ErrorList errors={errors} />
                        </Form.Item>
                    </>
                    )}
                </Form.List>              
            </Form>
        </Edit>
    );
};



 

