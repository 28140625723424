import React, { useEffect, useState } from 'react';
import { IResourceComponentsProps } from "@refinedev/core";
import { Alert, Divider, List, Space, Typography } from 'antd';
import { Show } from '@refinedev/antd';
import { useAuth0 } from '@auth0/auth0-react';

const { Title, Text } = Typography;


export const Home: React.FC<IResourceComponentsProps> = () => {

    const {
        user,
      } = useAuth0();

    const local_storage_prefix = "QA_PORTAL-" + process.env.REACT_APP_ENV + "-" + user?.sub + "-" + user[process.env.REACT_APP_BASE_URL + "/org_id"] + "-" + user[process.env.REACT_APP_BASE_URL + "/tenant_id"]   
    let [useRegionAlias, setUseRegionAlias] = React.useState(JSON.parse(localStorage.getItem(local_storage_prefix + '_use_region_alias')) || [])

    return (
        <Show isLoading={false}>
            {
                user?.[process.env.REACT_APP_BASE_URL + '/role_permissions']?.length == 0 ? 
                    <Space direction="vertical" style={{ width: '100%' }}>
                        <Alert
                        message="Error No User Roles found"
                        description="Error: No User Roles found"
                        type="error"
                        />
                    </Space> :
                    ""
            }
            <Title level={5}>Home</Title>
            <Text>This is the home page</Text>
            <Divider></Divider>
            <Text>Using API endpoints in region: <b>{useRegionAlias}</b></Text>
        </Show>
    );
};

