import React, { useEffect, useState } from 'react';
import { IResourceComponentsProps } from "@refinedev/core";

import {
    List,
    useTable,
    DateField,
    TagField,
    EditButton,
    ShowButton,
    TextField,
    DeleteButton,
    RefreshButton,
} from "@refinedev/antd";
import dayjs from "dayjs";
import { Table, Space, Tooltip, Alert, Divider } from "antd";

import { ILiveVersion } from "interfaces";


export const LiveVersionsList: React.FC<IResourceComponentsProps> = () => {
    const { tableProps, sorter, tableQueryResult } = useTable<ILiveVersion>({
        resource: "versions"
    });

    const refreshTableResults = () => {     
        tableQueryResult.refetch()
    };

    return (
        <List headerButtons={({  }) => (
            <>
              <RefreshButton disabled={tableQueryResult?.isLoading || tableQueryResult?.isFetching } loading={tableQueryResult?.isLoading || tableQueryResult?.isFetching } onClick={refreshTableResults} />
            </>
          )}>
            {
                tableQueryResult?.data?.summary?.summary.time_needed_in_seconds ?
                    <Space direction="vertical" style={{ width: '100%' }}>
                    <Alert
                    message={"Time taken to load the results: " + tableQueryResult?.data?.summary.summary.time_needed_in_seconds + " seconds"}
                    type="success"

                    />
                </Space> :
                ""
            }      
            <Divider />  
            <Table {...tableProps} rowKey="arn" dataSource={tableQueryResult?.data?.data }
                pagination={{
                    defaultPageSize:500,
                    showSizeChanger:true,
                }}>
                <Table.Column<ILiveVersion>
                    title="Id" 
                    dataIndex="arn" 
                    render={(_: any, record: { arn: any; }) => {
                        return (
                            <Tooltip title={record?.arn}>
                                <TagField
                                    value={`${
                                        record?.arn.substring(0,2)
                                    }` }
                                />
                            </Tooltip>
                        );
                    }}
                />
                <Table.Column<ILiveVersion>
                    sorter={(a:any, b:any) => a.application_name.localeCompare(b.application_name)}
                    filters={tableQueryResult?.data?.data.map((
                    { application_name }) => ({ "text": application_name, "value": application_name})).filter((v,i,a)=>a.findIndex(v2=>(v2.text===v.text))===i)}
                    filterMode='tree'
                    filterSearch={true}
                    onFilter={(value: any, record: { application_name: string; }) => record.application_name.startsWith(value)}
                    title="Application Name" 
                    dataIndex="application_name" 
                    render={(_: any, record: { application_name: any; }) => {
                        return (
                            <TagField
                                value={`${
                                    record?.application_name
                                }` }
                            />
                        );
                    }}
                />  
                <Table.Column<ILiveVersion>
                    sorter={(a:any, b:any) => a.environment.localeCompare(b.environment)}
                    filters={tableQueryResult?.data?.data.map((
                    { environment }) => ({ "text": environment, "value": environment})).filter((v,i,a)=>a.findIndex(v2=>(v2.text===v.text))===i)}
                    filterMode='tree'
                    filterSearch={true}
                    onFilter={(value: any, record: { environment: string; }) => record.environment.startsWith(value)}
                    title="Environment" 
                    dataIndex="environment" 
                    render={(_: any, record: { environment: any; }) => {
                        return (
                            <TagField
                                value={`${
                                    record?.environment
                                }` }
                            />
                        );
                    }}
                /> 
                <Table.Column<ILiveVersion>
                    sorter={(a:any, b:any) => a.region.localeCompare(b.region)}
                    filters={tableQueryResult?.data?.data.map((
                    { region }) => ({ "text": region, "value": region})).filter((v,i,a)=>a.findIndex(v2=>(v2.text===v.text))===i)}
                    filterMode='tree'
                    filterSearch={true}
                    onFilter={(value: any, record: { region: string; }) => record.region.startsWith(value)}
                    title="Region" 
                    dataIndex="region" 
                    render={(_: any, record: { region: any; }) => {
                        let colors = {
                            "us-west-2": "green",
                            "us-east-1": "lightgreen",
                            "eu-central-1": "blue",
                            "eu-west-1": "lightblue"
                        }

                        return (
                            <TagField
                                color={colors[record.region as keyof typeof colors ]}
                                value={`${
                                    record?.region
                                }` }
                            />
                        );
                    }}
                /> 
                <Table.Column<ILiveVersion>
                    sorter={(a:any, b:any) => a.env_type.localeCompare(b.env_type)}
                    filters={tableQueryResult?.data?.data.map((
                    { env_type }) => ({ "text": env_type, "value": env_type})).filter((v,i,a)=>a.findIndex(v2=>(v2.text===v.text))===i)}
                    filterMode='tree'
                    filterSearch={true}
                    onFilter={(value: any, record: { env_type: string; }) => record.env_type.startsWith(value)}
                    title="Blue/Green" 
                    dataIndex="env_type" 
                    render={(_: any, record: { env_type: any; }) => {
                        let colors = {
                            "blue": "blue",
                            "green": "green",
                            "blue_green": "orange",
                        }
                        return (
                            
                            <TagField
                                color={colors[record.env_type as keyof typeof colors ]}
                                value={`${
                                    record?.env_type
                                }` }
                            />
                        );
                    }}
                />   
                <Table.Column<ILiveVersion>
                    sorter={(a:any, b:any) => a.blue_value.localeCompare(b.blue_value)}
                    title="Blue Version" 
                    dataIndex="blue_value" 
                    render={(_: any, record: { blue_value: any; }) => {
                        return (
                            <TagField
                                value={`${
                                    record?.blue_value
                                }` }
                            />
                        );
                    }}
                />          
                <Table.Column<ILiveVersion>
                    sorter={(a:any, b:any) => a.blue_last_modified_date.localeCompare(b.blue_last_modified_date)}
                    title="Blue Last modified date" 
                    dataIndex="blue_last_modified_date" 
                    render={(_: any, record: { blue_last_modified_date: string; }) => {
                        return (
                            <DateField value={record?.blue_last_modified_date} format="LLL"></DateField>
                        );
                    }}
                />  
                <Table.Column<ILiveVersion>
                    sorter={(a:any, b:any) => a.green_value.localeCompare(b.green_value)}
                    title="Green Version" 
                    dataIndex="green_value" 
                    render={(_: any, record: { green_value: any; }) => {
                        return (
                            <TagField
                                value={`${
                                    record?.green_value
                                }` }
                            />
                        );
                    }}
                />          
                <Table.Column<ILiveVersion>
                    sorter={(a:any, b:any) => a.green_last_modified_date.localeCompare(b.green_last_modified_date)}
                    title="Green Last modified date" 
                    dataIndex="green_last_modified_date" 
                    render={(_: any, record: { green_last_modified_date: string; }) => {
                        return (
                            <DateField value={dayjs.unix(Number(record?.green_last_modified_date) || 1)} format="LLL"></DateField>
                        );
                    }}
                />     
                {/* <Table.Column<ILiveVersion>
                    title="Path" 
                    dataIndex="name" 
                    render={(_: any, record: { name: any; }) => {
                        return (
                            <TagField
                                value={`${
                                    record?.name
                                }` }
                            />
                        );
                    }}
                />     */}
                <Table.Column<ILiveVersion>
                    title="Actions"
                    dataIndex="arn"
                    render={(_: any, record: {
                            environment: string; 
                            region: string; 
                            application_name: any; 
                        }) => (
                        <Space>
                            <EditButton
                                hideText
                                size="small"
                                recordItemId={record.environment + "-" + record.region + "-" + record.application_name }
                            />
                            <ShowButton
                                hideText
                                size="small"
                                recordItemId={record.environment + "-" + record.region + "-" + record.application_name }
                            />
                        </Space>
                    )}
                />        
            </Table>
        </List>
    );
};

