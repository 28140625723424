import React, { useEffect, useState } from "react";

import { useShow, IResourceComponentsProps, useOne } from "@refinedev/core";

import { TagField, Show, EditButton, ShowButton, DateField } from "@refinedev/antd";

import { Typography } from "antd";

import { IBlueUser } from "interfaces";

const { Title, Text } = Typography;
import axios from 'axios';
import dayjs from "dayjs";


export const BlueUserShow: React.FC<IResourceComponentsProps> = () => {
    const { queryResult } = useShow<IBlueUser>();
    const { data, isLoading } = queryResult;
    const record = data?.data;

    return (
        <Show isLoading={isLoading}>
            <Title level={5}>User id</Title>
            <Text>{record?.user_id}</Text>
            <Title level={5}>Name</Title>
            <Text>{record?.label}</Text>
            <Title level={5}>Tenant id</Title>  
            <Text>{record?.tenant_id}</Text>
            <Title level={5}>Description</Title>
            <Text>{record?.description}</Text>
            <Title level={5}>Type</Title>
            <Text>{record?.type}</Text>
            <Title level={5}>Application</Title>
            <Text>{record?.application}</Text>
            <Title level={5}>Config</Title>
            <Text><pre>{record?.config}</pre></Text>
        </Show>
    );
};

