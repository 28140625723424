import React, { useEffect, useState } from 'react';
import { HttpError, IResourceComponentsProps, useNotification, useOne, useShow } from "@refinedev/core";
import { Alert, Avatar, Button, Card, Col, Divider, List, Row, Space, Table, Tag, Tooltip, Typography } from 'antd';
import { Show, ShowButton, TagField, useTable } from '@refinedev/antd';
import { useAuth0 } from '@auth0/auth0-react';
import { IDemo } from 'interfaces';
import axios from 'axios';

const { Title, Text } = Typography;
import { CaretRightOutlined } from '@ant-design/icons';
import { Link, useParams } from 'react-router-dom';
import { EditOutlined, DeleteOutlined, SettingOutlined, ExpandAltOutlined, CloseOutlined, CheckOutlined, ArrowUpOutlined, ArrowDownOutlined } from '@ant-design/icons';
import Meta from 'antd/es/card/Meta';

export const DemoShow: React.FC<IResourceComponentsProps> = () => {

    const {
        user,
      } = useAuth0();
    const { open } = useNotification();

    const { queryResult } = useShow<IDemo>();
    const { data, isLoading } = queryResult;
    const record = data?.data;
       
    const params= useParams()
    
    let colors = {
        "us-west-2": "green",
        "us-east-1": "lightgreen",
        "eu-central-1": "blue",
        "eu-west-1": "lightblue"
    }
 
    return (
        <Show isLoading={queryResult?.isLoading || queryResult?.isFetching }>
            <Title level={5}>Demo</Title>
            <Text>{record?.demo_id}</Text>
            <Title level={5}>Environment</Title>
            <Text>{record?.environment}</Text>
            <Title level={5}>Continent</Title>
            <Text>{record?.continent}</Text>
            <Title level={5}>Region</Title>
            <Text>{record?.region}</Text>
            <Title level={5}>Org id</Title>
            <Text>{record?.org_id}</Text>
            <Title level={5}>Tenant Id</Title>
            <Text>{record?.tenant_id}</Text>
            <Title level={5}>Created by Id</Title>
            <Text>{record?.created_by}</Text>
            
            <Divider></Divider>
            <Title level={5}>Demo user(s)</Title>
            <Row gutter={16}>
            {
            record ? record?.users?.map((record: any, index: any) => 
                <><Col span={12}>
                <Card
                    style={{ marginTop: 16 }}
                    hoverable={true}
                    bordered={false}
                    actions={[
                    <Tooltip title="More"><Link to={'https://' + process.env.REACT_APP_API_BASE_URL + "/transactions?pageSize=10&current=1&group_name="+ record.group_name} target="_blank"><ExpandAltOutlined key="more" /></Link></Tooltip>,
                    ]}
                >
                        <Meta
                            title={record?.user_id}
                            description={record?.name}
                            avatar={<Avatar src={`https://api.dicebear.com/7.x/miniavs/svg?seed=${index}`} />}
                        />
                        {         
                            <pre>{JSON.stringify(record, null, 2)}</pre>
                        }
                    </Card>
                </Col>
                </>
            ) : ""}
            </Row>
        </Show>
    );
};

