import { AxiosInstance } from "axios";
import { RequestQueryBuilder, CondOperator } from "@nestjsx/crud-request";
import { DataProvider } from "@refinedev/core";
import {
    handleFilter,
    handlePagination,
    handleSort,
    handleJoin,
    axiosInstance,
} from "./utils";

export const dataProvider = (
    apiUrl: string,
    httpClient: AxiosInstance = axiosInstance,
): Required<DataProvider> => ({
    getList: async ({ resource, pagination, filters, sorters, meta }) => {
        console.log("getList")
        console.log(meta)
        const token = JSON.parse(localStorage.getItem('token') || '{}');
        const org_id = token[process.env.REACT_APP_BASE_URL + "/org_id"] 
        const continent = token[process.env.REACT_APP_BASE_URL + "/continent"] 
        const region = token[process.env.REACT_APP_BASE_URL + "/region"] 
        const domain = process.env.REACT_APP_API_BASE_URL
        
        if(resource == "versions" ){
            const id = meta?.id ? meta?.id : null
            let url = ""
            if(id){
                url = `${apiUrl}/internal/tools/release-management/GET/${continent.toUpperCase()}/v0/${resource}/${id}`
                console.log(url)
                const { data, headers } = await httpClient.get(url);
                console.log(data)
                const summary = JSON.parse(headers["x-summary"])
    
                return {
                    data: data.detailed_results,
                    application_summary: data,
                    summary,
                    total: 10,
                };
            } else{
                url = `${apiUrl}/internal/tools/release-management/GET/${continent.toUpperCase()}/v0/${resource}`
                console.log(url)
                const { data, headers } = await httpClient.get(url);
                console.log(data)
                const summary = JSON.parse(headers["x-summary"])
    
                return {
                    data: data,
                    summary,
                    total: 10,
                };
            }
        } 
        else if(resource == "blue-users" ){
            let url = `${apiUrl}/internal/tools/blue-green-mgmt/GET/${continent.toUpperCase()}/v0/${resource}`
            console.log(url)
            const { data, headers } = await httpClient.get(url);
            console.log(data)
            const summary = JSON.parse(headers["x-summary"])

            return {
                data: data,
                summary,
                total: 10,
            };
        } 
        else if(resource == "demos" ){
            let url = `${apiUrl}/internal/tools/demos/GET/${continent.toUpperCase()}/v0/${resource}`
            console.log(url)
            const { data, headers } = await httpClient.get(url);
            console.log(data)
            const summary = JSON.parse(headers["x-summary"])

            return {
                data: data,
                summary,
                total: 10,
            };
        } 
        else{
            const { data } = await httpClient.get(`${apiUrl}/${resource}`);

            return {
                data: data,
                total: 10,
            };
        }

    },

    getMany: async ({ resource, ids, meta }) => {
        console.log("getMany")
        const url = `${apiUrl}/${resource}`;

        let query = RequestQueryBuilder.create().setFilter({
            field: "id",
            operator: CondOperator.IN,
            value: ids,
        });

        query = handleJoin(query, meta?.join);

        const { data } = await httpClient.get(`${url}?${query.query()}`);

        return {
            data,
        };
    },

    create: async ({ resource, variables }) => {
        console.log("create")
        const token = JSON.parse(localStorage.getItem('token') || '{}');
        const org_id = token[process.env.REACT_APP_BASE_URL + "/org_id"] 
        const continent = token[process.env.REACT_APP_BASE_URL + "/continent"] 
        const region = token[process.env.REACT_APP_BASE_URL + "/region"] 
        const domain = process.env.REACT_APP_API_BASE_URL

        if(resource == "blue-users" ){
            // type ObjectKey = keyof typeof variables;

            // const myVar = 'user_id' as ObjectKey;
            // const user_id = variables[myVar];

            // const myVar2 = 'tenant_id' as ObjectKey;
            // const tenant_id = variables[myVar];

            let url = `${apiUrl}/internal/tools/blue-green-mgmt/ANY/${continent.toUpperCase()}/v0/${resource}`
            console.log(url)
            const { data, headers } = await httpClient.post(url, variables);
            console.log(data)
            const summary = JSON.parse(headers["x-summary"])

            return {
                data: data,
                summary,
            };
        } 
        if(resource == "demos" ){
            let url = `${apiUrl}/internal/tools/demos/ANY/${continent.toUpperCase()}/v0/${resource}`
            console.log(url)
            const { data, headers } = await httpClient.post(url, variables);
            console.log(data)
            const summary = JSON.parse(headers["x-summary"])

            return {
                data: data,
                summary,
            };
        } 
        else{
            apiUrl = "https://pdx.us.management.sandbox.authe.io/setup/dashboard/ANY/US/v0"
            
            const url = `${apiUrl}/${resource}`;
            console.log(url)
            const { data } = await httpClient.post(url, variables);
            // console.log(data)
            return {
                data,
            };
        }
    },

    update: async ({ resource, id, variables }) => {
        console.log("update")
        const token = JSON.parse(localStorage.getItem('token') || '{}');
        const org_id = token[process.env.REACT_APP_BASE_URL + "/org_id"] 
        const continent = token[process.env.REACT_APP_BASE_URL + "/continent"] 
        const region = token[process.env.REACT_APP_BASE_URL + "/region"] 
        const domain = process.env.REACT_APP_API_BASE_URL

        if(resource == "blue-users" ){
            const split_array = String(id).split("-root-")
            const tenant_id = "root"
            const user_id = split_array[0]
            const application_name = split_array[1]

            let url = `${apiUrl}/internal/tools/blue-green-mgmt/ANY/${continent.toUpperCase()}/v0/${resource}/tenants/${tenant_id}/users/${user_id}?application_name=${application_name}`
            console.log(url)
            const { data, headers } = await httpClient.put(url, variables);
            console.log(data)
            const summary = JSON.parse(headers["x-summary"])

            return {
                data: data,
                summary,
            };
        } 
        else{
            const url = `${apiUrl}/${resource}/${id}`;

            const { data } = await httpClient.patch(url, variables);
    
            return {
                data,
            };
        }
    },

    updateMany: async ({ resource, ids, variables }) => {
        console.log("updateMany")
        const response = await Promise.all(
            ids.map(async (id) => {
                const { data } = await httpClient.patch(
                    `${apiUrl}/${resource}/${id}`,
                    variables,
                );
                return data;
            }),
        );

        return { data: response };
    },

    createMany: async ({ resource, variables }) => {
        console.log("createMany")
        const url = `${apiUrl}/${resource}/bulk`;

        const { data } = await httpClient.post(url, { bulk: variables });

        return {
            data,
        };
    },

    
    getOne: async ({ resource, id, meta }) => {
        console.log("getOne")
        console.log(resource)
        console.log( meta?.join)
        const token = JSON.parse(localStorage.getItem('token') || '{}');
        const org_id = token[process.env.REACT_APP_BASE_URL + "/org_id"] 
        const continent = token[process.env.REACT_APP_BASE_URL + "/continent"] 
        const region = token[process.env.REACT_APP_BASE_URL + "/region"] 
        const domain = process.env.REACT_APP_API_BASE_URL


        if(resource == "live-versions" ){
            resource = "versions"
            const url = `${apiUrl}/internal/tools/release-management/GET/${continent.toUpperCase()}/v0/${resource}/${id}`
            console.log(url)
            const { data, headers } = await httpClient.get(url);
            console.log(data)
            const summary = JSON.parse(headers["x-summary"])

            return {
                data: data,
                summary,
            };
        } 
        else if(resource == "blue-users" ){
            const split_array = String(id).split("-root-")
            const tenant_id = "root"
            const user_id = split_array[0]
            const application_name = split_array[1]
            const url = `${apiUrl}/internal/tools/blue-green-mgmt/GET/${continent.toUpperCase()}/v0/${resource}/tenants/${tenant_id}/users/${user_id}?application_name=${application_name}`
            console.log(url)
            const { data, headers } = await httpClient.get(url);
            console.log(data)
            const summary = JSON.parse(headers["x-summary"])

            return {
                data: data,
                summary,
            };
        } 
        else if(resource == "demos" ){
            const url = `${apiUrl}/internal/tools/demos/GET/${continent.toUpperCase()}/v0/${resource}/${id}`
            console.log(url)
            const { data, headers } = await httpClient.get(url);
            console.log(data)
            const summary = JSON.parse(headers["x-summary"])

            return {
                data: data,
                summary,
            };
        } 
        else{
                
            const url = `${apiUrl}/${resource}/${id}`;

            const { data, headers } = await httpClient.get(url)
            const summary = JSON.parse(headers["x-summary"])
            return {
                data,
                summary,
            };
        }
    },

    deleteOne: async ({ resource, id }) => {
        console.log("deleteOne")
        const token = JSON.parse(localStorage.getItem('token') || '{}');
        const org_id = token[process.env.REACT_APP_BASE_URL + "/org_id"] 
        const continent = token[process.env.REACT_APP_BASE_URL + "/continent"] 
        const region = token[process.env.REACT_APP_BASE_URL + "/region"] 
        const domain = process.env.REACT_APP_API_BASE_URL

        if(resource == "blue-users" ){
            const split_array = String(id).split("-root-")
            const tenant_id = "root"
            const user_id = split_array[0]
            const application_name = split_array[1]

            let url = `${apiUrl}/internal/tools/blue-green-mgmt/ANY/${continent.toUpperCase()}/v0/${resource}/tenants/${tenant_id}/users/${user_id}?application_name=${application_name}`
            console.log(url)
            const { data, headers } = await httpClient.delete(url);
            console.log(data)
            const summary = JSON.parse(headers["x-summary"])

            return {
                data: data,
                summary,
            };
        } 
        else {
            const url = `${apiUrl}/${resource}/${id}`;

            const { data } = await httpClient.delete(url);
    
            return {
                data,
            };
        }
    },

    deleteMany: async ({ resource, ids }) => {
        console.log("deleteMany")
        const response = await Promise.all(
            ids.map(async (id) => {
                const { data } = await httpClient.delete(
                    `${apiUrl}/${resource}/${id}`,
                );
                return data;
            }),
        );
        return { data: response };
    },

    getApiUrl: () => {
        return apiUrl;
    },

    custom: async ({
        url,
        method,
        meta,
        filters,
        sorters,
        payload,
        query,
        headers,
    }) => {
        let requestQueryBuilder = RequestQueryBuilder.create();

        requestQueryBuilder = handleFilter(requestQueryBuilder, filters);

        requestQueryBuilder = handleJoin(requestQueryBuilder, meta?.join);

        requestQueryBuilder = handleSort(requestQueryBuilder, sorters);

        let requestUrl = `${url}?${requestQueryBuilder.query()}`;

        // if (headers) {
        //     httpClient.defaults.headers = {
        //         ...httpClient.defaults.headers,
        //         ...headers,
        //     };
        // }

        let axiosResponse;
        switch (method) {
            case "put":
            case "post":
            case "patch":
                axiosResponse = await httpClient[method](url, payload);
                break;
            case "delete":
                axiosResponse = await httpClient.delete(url, {
                    data: payload,
                });
                break;
            default:
                axiosResponse = await httpClient.get(requestUrl);
                break;
        }

        const { data } = axiosResponse;

        return Promise.resolve({ data });
    },
});
