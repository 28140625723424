import React, { useMemo, useRef, useState } from "react";
import { IResourceComponentsProps } from "@refinedev/core";

import { Create, Edit, useForm } from "@refinedev/antd";

import { Button, Checkbox, Form, Input, Select, SelectProps, Spin } from "antd";

import { IBlueUser } from "interfaces";
import debounce from 'lodash/debounce';
import { MinusCircleOutlined, PlusOutlined } from "@ant-design/icons";

export interface DebounceSelectProps<ValueType = any>
  extends Omit<SelectProps<ValueType | ValueType[]>, 'options' | 'children'> {
  fetchOptions: (search: string) => Promise<ValueType[]>;
  debounceTimeout?: number;
}

export const BlueUserCreate: React.FC<IResourceComponentsProps> = () => {
    const token = JSON.parse(localStorage.getItem('token') || '{}');
    const local_storage_prefix = "QA_PORTAL-" + process.env.REACT_APP_ENV + "-" + token?.sub + "-" + token[process.env.REACT_APP_BASE_URL + "/org_id"] + "-" + token[process.env.REACT_APP_BASE_URL + "/tenant_id"]   
    let storage_use_region_alias = JSON.parse(localStorage.getItem(local_storage_prefix + '_use_region_alias') || '"default"')

    const continent = token[process.env.REACT_APP_BASE_URL + "/continent"] 
    const region_config = token[process.env.REACT_APP_BASE_URL + "/region_config"]   
    const region = region_config?.region
    const region_alias = region_config?.region_alias
    const backup_region = region_config?.backup_region
    const backup_region_alias = region_config?.backup_region_alias
    const domain = process.env.REACT_APP_API_BASE_URL 
    const org_id = token[process.env.REACT_APP_BASE_URL + "/org_id"]

    let use_region
    let use_region_alias:string
    let use_backup_region
    let use_backup_region_alias
    
    if(storage_use_region_alias && storage_use_region_alias != "default" && storage_use_region_alias != undefined){
        if(storage_use_region_alias == region_alias){
            use_backup_region = backup_region
            use_backup_region_alias = backup_region_alias
            use_region = region
            use_region_alias = region_alias                   
        } else{
            // Switch
            use_backup_region = region
            use_backup_region_alias = storage_use_region_alias
            use_region = backup_region
            use_region_alias = backup_region_alias
        }
    } else{
        // Switch region based on time/minutes. To semi-randomly switch to backup region
        const now = new Date().getUTCMinutes() % 5 // get remainder of 5 minutes interval and check if this is greater than something. First 3 minutes 1 region. Remainder 2 minutes other region
        if(now > 2){ // 3 and 4
            use_backup_region = region_alias 
            use_backup_region_alias = region_alias 
            use_region = backup_region
            use_region_alias = backup_region_alias
            console.log("Using back up region: " + use_region_alias) 
        }
        else{ // 0,1,2
            use_backup_region = backup_region
            use_backup_region_alias = backup_region_alias
            use_region = region
            use_region_alias = region_alias
            console.log("Using main region: " + use_region_alias) 
        }
    }

    const new_api_endpoint = "https://" + use_region_alias + "." + continent.toLowerCase()  + ".api." + process.env.REACT_APP_API_BASE_URL

    
    const { formLoading ,formProps, saveButtonProps, onFinish } = useForm<IBlueUser>(
        {
            resource: "blue-users",
            action: "create",
        }
    );

    const { Option } = Select;

    const [providedOrgId, setProvidedOrgId] = useState<string>();

    const [value, setValue] = useState<BlueUserValue[]>([]);

    function DebounceSelect<
        ValueType extends { key?: string; label: React.ReactNode; value: string | number } = any,
        >({ fetchOptions, debounceTimeout = 800, ...props }: DebounceSelectProps<ValueType>) {
        const [fetching, setFetching] = useState(false);
        const [options, setOptions] = useState<ValueType[]>([]);
        const fetchRef = useRef(0);

        const debounceFetcher = useMemo(() => {
            const loadOptions = (value: string) => {
            fetchRef.current += 1;
            const fetchId = fetchRef.current;
            setOptions([]);
            setFetching(true);

            fetchOptions(value).then((newOptions) => {
                if (fetchId !== fetchRef.current) {
                // for fetch callback order
                return;
                }

                setOptions(newOptions);
                setFetching(false);
            });
            };

            return debounce(loadOptions, debounceTimeout);
        }, [fetchOptions, debounceTimeout]);

        return (
            <Select
            labelInValue
            filterOption={false}
            onSearch={debounceFetcher}
            notFoundContent={fetching ? <Spin size="small" /> : null}
            {...props}
            options={options}
            />
        );
    }

    async function fetchBlueUserList(username: string) {
            console.log('fetching users', username);
        
        

            const requestHeaders = {
                Authorization: `Bearer ${token.__raw}`,
                Accept: "application/json, text/plain, */*",
                "Source-Platform": "qa-portal",
                "Source-Region": region,
                "Destination-Region": region,
            };

            const qa_config = token[process.env.REACT_APP_BASE_URL + "/qa_config"] 
            const qa_environment = qa_config["environment"]
            const blue_config = qa_config["config"]

            console.log("Sending the request")
            let url
            if (qa_environment == "blue"){
                url = "https://"  + use_region_alias + "." + continent.toLowerCase() + ".api." + domain + "/management/permissions/blue/GET/" + continent.toUpperCase() + "/v0/users?org_id=" + providedOrgId + "&tenant_id=root"
            } 
            else{
                url = "https://"  + use_region_alias + "." + continent.toLowerCase() + ".api." + domain + "/management/permissions/GET/" + continent.toUpperCase() + "/v0/users?org_id=" + providedOrgId + "&tenant_id=root"
            }
            console.log(url)
        
        
            return fetch(url, {headers: requestHeaders})
            .then((response) => 
                response.json() 
            )
            .then((body) =>
                body.result.map(
                (user: { name: string; email : string; tenant_id : string; id : string }) => ({
                    label: user.email + " - " + user.name,
                    value: user.id,
                }),
                ),
            );
      }

    // Usage of DebounceSelect
    interface BlueUserValue {
        label: string;
        value: string;
        id: string;
        tenant_id: string
    }

    const formItemLayout = {
        labelCol: {
          xs: { span: 24 },
          sm: { span: 4 },
        },
        wrapperCol: {
          xs: { span: 24 },
          sm: { span: 20 },
        },
      };
      
      const formItemLayoutWithOutLabel = {
        wrapperCol: {
          xs: { span: 24, offset: 0 },
          sm: { span: 20, offset: 4 },
        },
      };

      
    return (
        <Create isLoading={formLoading} saveButtonProps={saveButtonProps} resource="blue-users">
            <Form {...formProps} layout="vertical" >
                <Form.Item
                    label="Type"
                    name="type"  
                    initialValue="internal"                  
                    rules={[
                        {
                            required: true,
                        },
                    ]}
                >
                    <Select 
                        disabled={true}
                        placeholder="Please select an application">
                        <Option value="internal">Internal</Option>
                        <Option value="external">External</Option>
                    </Select>
                </Form.Item>
                <Form.Item
                    label="Application"
                    name="application"  
                    initialValue="admin-portal"                  
                    rules={[
                        {
                            required: true,
                        },
                    ]}
                >
                    <Select 
                        placeholder="Please select an application">
                        <Option value="dashboard">Dashboard</Option>
                        <Option value="admin-portal">Admin-Portal</Option>
                        <Option value="analytics-portal">Analytics-Portal</Option>
                        <Option value="selfservice-portal">Selfservice-Portal</Option>
                        <Option value="qa-portal">QA-Portal</Option>
                    </Select>
                </Form.Item>
                <Form.Item
                    label="Org Id"
                    name="org_id"       
                    rules={[
                        {
                            required: true,
                        },
                    ]}
                >
                    <Input placeholder="Enter an org id and then you can search for the user" onChange={(e) => {
                        console.log(e.target.value)
                        setProvidedOrgId(e.target.value)
                    }}
                    />
                </Form.Item>
                <Form.Item
                    label="User id"
                    name="user_id"   
                    rules={[
                        {
                            required: true,
                        },
                    ]}
                >
                <DebounceSelect
                    mode="multiple"
                    value={value}
                    placeholder="First enter an org id and then start typing an user name" 
                    fetchOptions={fetchBlueUserList}
                    onChange={(newValue, options) => {
                        setValue(newValue as BlueUserValue[]);
                    }}
                    style={{ width: '100%' }}
                    />
                </Form.Item>  
                <Form.Item
                    label="Description"
                    name="description"                    
                    rules={[
                        {
                            required: true,
                        },
                    ]}
                >
                    <Input/>
                </Form.Item>
                <Form.List
                    name="config"
                    // rules={[
                    // {
                    //     validator: async (_, config) => {
                    //     if (!config || config.length < 0) {
                    //         return Promise.reject(new Error("At least 2 passengers"));
                    //     }
                    //     },
                    // },
                    // ]}
                >
                    {(fields, { add, remove }, { errors }) => (
                    <>
                        {fields.map((field, index) => (
                        <Form.Item
                            {...formItemLayout}
                            label={index === 0 ? "Service Config:" : ""}
                            required={false}
                            key={field.key}
                        >
                            <Form.Item
                            {...field}
                            validateTrigger={["onChange", "onBlur"]}
                            rules={[
                                {
                                required: false,
                                whitespace: true,
                                message:
                                    "Please input the service resource name. For example: permissions.",
                                },
                            ]}
                            noStyle
                            >
                            <Input
                                placeholder="service resource name"
                                style={{ width: "60%" }}
                            />
                            </Form.Item>
                            {fields.length > 0 ? (
                            <MinusCircleOutlined
                                className="dynamic-delete-button"
                                onClick={() => remove(field.name)}
                            />
                            ) : null}
                        </Form.Item>
                        ))}
                        <Form.Item>
                        <Button
                            type="dashed"
                            onClick={() => add()}
                            style={{ width: "60%" }}
                            icon={<PlusOutlined />}
                        >
                            Add Service config
                        </Button>
                        <Form.ErrorList errors={errors} />
                        </Form.Item>
                    </>
                    )}
                </Form.List>
            </Form>
        </Create>
    );
};
