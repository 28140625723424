import React, { useEffect, useState } from 'react';
import { IResourceComponentsProps, useShow } from "@refinedev/core";

import {
    List,
    useTable,
    DateField,
    TagField,
    EditButton,
    ShowButton,
    TextField,
    DeleteButton,
    Show,
    RefreshButton,
    ListButton,
} from "@refinedev/antd";
import dayjs from "dayjs";
import { Table, Space, Typography, Checkbox, Input, Button, Divider, Alert, Tooltip, Spin, Tag } from "antd";
import { SearchOutlined } from '@ant-design/icons';
import { isAddress } from 'viem'

import { ILambdaAlias, ILiveVersionDetail, ILiveVersionDetailedResult } from "interfaces";
import { useAuth0 } from '@auth0/auth0-react';
import { CheckboxChangeEvent } from 'antd/es/checkbox';
import { CheckboxValueType } from 'antd/es/checkbox/Group';
import { useParams, useSearchParams } from 'react-router-dom';
const { Title, Text } = Typography;


export const LiveVersionShow: React.FC<IResourceComponentsProps> = () => {
    const CheckboxGroup = Checkbox.Group;
    let colors = {
        "us-west-2": "green",
        "us-east-1": "lightgreen",
        "eu-central-1": "blue",
        "eu-west-1": "lightblue"
    }
    const {
        user,
      } = useAuth0();
    const params= useParams()

    const { tableProps, tableQueryResult } = useTable<ILiveVersionDetail>({
        resource: "versions",
        meta: {
            id: params.id
        }
    });

    const { data, isLoading } = tableQueryResult;
    const record = data?.application_summary;
      
    const refreshTableResults = () => {     
        tableQueryResult.refetch()
    };

    return (

        <List headerButtons={() => (
           <></>
          )}>
            <Show breadcrumb={false} title={false} isLoading={false} headerButtons={({ deleteButtonProps, editButtonProps, listButtonProps, refreshButtonProps }) => (
        <>
          {listButtonProps && <ListButton {...listButtonProps} meta={{ foo: "bar" }} />}
          {editButtonProps && <EditButton {...editButtonProps} meta={{ foo: "bar" }} />}
          {deleteButtonProps && <DeleteButton {...deleteButtonProps} meta={{ foo: "bar" }} />}
          <RefreshButton {...refreshButtonProps} disabled={tableQueryResult?.isLoading || tableQueryResult?.isFetching } loading={tableQueryResult?.isLoading || tableQueryResult?.isFetching } onClick={refreshTableResults} />
        </>
      )} >
                {
                    tableQueryResult?.data?.summary ?
                        <Space direction="vertical" style={{ width: '100%' }}>
                        <Alert
                        message={"Time taken to load the results: " + tableQueryResult?.data?.summary?.summary.time_needed_in_seconds + " seconds"}
                        type="success"

                        />
                    </Space> :
                    ""
                } 
                <Divider></Divider>
                {isLoading ? <Spin size="large" /> : null}
                <Title level={5}>Application name</Title>
                <Text>{record?.application_name}</Text>
                <Title level={5}>Environment</Title>
                <Text>{record?.environment}</Text>
                <Title level={5}>Region</Title>
                <Tag color={colors[record?.region as keyof typeof colors ]}>{record?.region}</Tag>
                <Title level={5}>Region Alias</Title>
                <Text>{record?.region_alias}</Text>
                <Title level={5}>Blue version as displayed in SSM</Title>
                <Text>{record?.blue_version.blue_value}</Text>
                <Title level={5}>Green version as displayed in DB</Title>
                <Text>{record?.green_version.green_value ? record?.green_version.green_value : "1 - Not yet deployed"}</Text>
                <Title level={5}>Api gateway Id</Title>
                <Text>{record?.api_gateway_id}</Text>
                <Title level={5}>Api gateway Name</Title>
                <Text>{record?.api_gateway_name}</Text>
                <Title level={5}>Api gateway <span style={{color: "blue"}}>Blue</span> endpoint is pointing to environment</Title>
                <Tag color={record?.api_gateway_stage_variables.LatestEnvironment}>{record?.api_gateway_stage_variables.LatestEnvironment}</Tag>         
                <Title level={5}>Api gateway <span style={{color: "lightgreen"}}>Green</span>  endpoint is pointing to environment</Title>
                <Tag color={record?.api_gateway_stage_variables.LiveEnvironment}>{record?.api_gateway_stage_variables.LiveEnvironment}</Tag>                          
                <Divider></Divider>


            </Show>
            <Divider />     
            <Table {...tableProps} dataSource={tableQueryResult?.data?.data} loading={tableQueryResult?.isLoading || tableQueryResult?.isFetching } rowKey="lambda_arn"
            pagination={{
                defaultPageSize:100,
                showSizeChanger:true,
            }}
            
            >
                <Table.Column<ILiveVersionDetailedResult>
                    title="Lambda Arn" 
                    dataIndex="lambda_arn" 
                    render={(_: string) => {
                        return (
                            <Tooltip title={_}>
                                <TagField
                                    value={`${
                                        _.split(":function:").pop()
                                    }` }
                                />
                            </Tooltip>
                        );
                    }}
                />
                <Table.Column<ILiveVersionDetailedResult>
                    title="Lambda Blue Latest version info" 
                    dataIndex="lambda_arn" 
                    render={(_: any, record) => {
                        return (
                            <div>
                                <Tooltip title="Application internal version">
                                <TagField
                                    color="blue"
                                    value={`${
                                        record.lambda_alias_result.aliases_info.filter((alias: any) => alias.alias_name == "blue")[0]?.version
                                    }` }
                                />
                                </Tooltip>
                                <Tooltip title="Lambda internal version">
                                <TagField
                                    color="lightblue"
                                    value={`${
                                        record.lambda_alias_result.aliases_info.filter((alias: any) => alias.alias_name == "blue")[0]?.lambda_version
                                    }` }
                                />
                                </Tooltip>
                            </div>
                        );
                    }}
                />
                <Table.Column<ILiveVersionDetailedResult>
                    title="Lambda Green Latest version info" 
                    dataIndex="lambda_arn" 
                    render={(_: any, record) => {
                        return (
                            <div>
                                <Tooltip title="Application internal version">
                                <TagField
                                    color="green"
                                    value={`${
                                        record.lambda_alias_result.aliases_info.filter((alias: any) => alias.alias_name == "green")[0]?.version
                                    }` }
                                />
                                </Tooltip>
                                <Tooltip title="Lambda internal version">
                                <TagField
                                    color="lightgreen"
                                    value={`${
                                        record.lambda_alias_result.aliases_info.filter((alias: any) => alias.alias_name == "green")[0]?.lambda_version
                                    }` }
                                />
                                </Tooltip>
                            </div>
                        );
                    }}
                />
                <Table.Column<ILiveVersionDetailedResult>
                    title="Lambda deployed version info (Only the last x versions and the first one retrieved)" 
                    dataIndex="lambda_arn" 
                    render={(_: any, record) => {
                        return (
                            record.lambda_version_result.map((version_info: any) =>  <Tooltip title={version_info.lambda_version}> <TagField id={version_info.version} key={version_info.version} value={version_info.version} color={ (version_info.version == record.lambda_alias_result.aliases_info.filter(( alias: any) => alias.alias_name == "green")[0]?.version && version_info.version == record.lambda_alias_result.aliases_info.filter(( alias: any) => alias.alias_name == "blue")[0]?.version) ? "orange" : version_info.lambda_version == "$LATEST" ? "lightblue": version_info.lambda_version == record.lambda_alias_result.aliases_info.filter(( alias: any) => alias.alias_name == "blue")[0]?.lambda_version ? "blue" :  version_info.lambda_version == record.lambda_alias_result.aliases_info.filter((alias: any) => alias.alias_name == "green")[0]?.lambda_version ? "green" : ""} ></TagField></Tooltip>)
                        );
                    }}
                />
            </Table>
        </List>
    );
};


